import { Link } from "gatsby"
import React from "react"
import GetGGFXImage from "../common/site/GetGGFXImage"
import "./AreaguideCard.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { areaGuide } from "../../site/urls"

const AreaguideCard = ({ areaguide }) => {
  let processedImages = areaguide.imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})
  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div className="areaguide-card" variants={fadeInFromTop}>
            <Link to={`${areaGuide}${areaguide.slug}`}>
              <GetGGFXImage
                imagename={"area-guides.tile_image.tileimg"}
                imagesource={areaguide.tile_image}
                key={areaguide.tile_image.url}
                fallbackalt={areaguide.name}
                imagetransformresult={processedImages}
                id={areaguide.id}
                className="areaguide-img"
              />
            </Link>
            <div className="content">
              <Link to={`${areaGuide}${areaguide.slug}/`}>
                <p className="title">{areaguide.name}</p>
              </Link>
              <p className="price">{areaguide.price}</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default AreaguideCard
