import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import AreaguideCard from "../AreaguideCard/AreaguideCard"
import SliderModule from "../SliderModule/SliderModule"
import "./AreaguideSlider.scss"
import {
  inViewOptions2,
  containerVariants,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { sortItems } from "../common/site/utils"

const AreaguideSlider = ({ module }) => {
  const data = useStaticQuery(graphql`
    query getAreaguideSliderData {
      glstrapi {
        areaGuides(where: {publish:true } , limit:6) {
          ...areaguideDetails
        }
      }
    }
  `)

  const areaguidesData = data.glstrapi.areaGuides?.sort((a,b)=>sortItems(a.sort_order,b.sort_order));
  const areaguides = areaguidesData?.slice(0,12)

  const sliderData = areaguides.map(areaguide => (
    <AreaguideCard areaguide={areaguide} />
  ))

  return (
    <InView {...inViewOptions2}>
    {({ ref, inView }) => (
      <motion.div
        className="animation-component"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
    <div className="areaguide-slider-module">
      <SliderModule
        title={module.title}
        description={module.description}
        sliderData={sliderData}
        className="areaguide-slider-wrap"
        sliderClassName="areaguide-slider-section"
        theme={module.theme}
        size="md"
      />
    </div>
    </motion.div>
    )}
    </InView>
  )
}

export default AreaguideSlider
